import { graphql } from 'gatsby'
import React from 'react'

import { IndexQueryQuery } from '../../../types/graphql-types'
import Meta from '../../components/meta/meta'
import Layout from '../../components/layout/layout'

interface Props {
  data: IndexQueryQuery
  location: Location
}

const NewHeaderMenu: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta

  return (
    <Layout edition="01-2021" location={location}>
      <Meta site={meta} />
      <div className="content-inner shadow-lg">
        <div
          className="page-header"
          style={{ backgroundImage: 'url(../../img/lon-new-header-menu.jpg)' }}
        ></div>
        <article className="post">
          <h1>New header menu</h1>
          <p>
            We want to be innovative, more client centric and deliver a good
            experience; to reach this goal, one of the main points is the way we
            display and provide the right content to our users at the right
            time. To make a step ahead towards this goal, we are rethinking our
            global main menu on mobile and desktop.
          </p>
          <p>
            New main menu organization, new designs, multiple ways to find the
            perfect Longines watch, new UX.
          </p>

          <div className="spacer"></div>

          <div className="row align-items-center justify-content-center">
            <div className="col-md-8">
              <div className="alert alert-light text-primary shadow">
                <div className="d-flex align-items-center">
                  <ion-icon
                    name="rocket-outline"
                    style={{ marginRight: '30px', fontSize: '36px' }}
                  ></ion-icon>
                  <div>
                    <h3>Go live ?</h3>
                    <p className="mb-0">
                      This new menu will come in April 2021
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <blockquote>
            <div className="d-flex">
              <img
                src={'../../img/steven-valente.jpg'}
                className="rounded-circle shadow-lg mr-3"
                style={{ width: '80px', height: '80px' }}
              />
              <div>
                <h4>Need further information?</h4>
                <p>
                  Please email{' '}
                  <a href="mailto:steven.valente@longines.com">
                    steven.valente@longines.com
                  </a>{' '}
                  and he will be glad to answer your questions and organize a
                  Skype call at your convenience.
                </p>
              </div>
            </div>
          </blockquote>
        </article>
      </div>
    </Layout>
  )
}

export default NewHeaderMenu

export const pageQuery = graphql`
  query NewHeaderMenuQuery_01_2021 {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
      }
    }
  }
`
